<template>
  <!--begin::Dashboard-->
  <div>
    <div class="row">
      <div class="col-xl-4">
        <h2>{{ $t("dashboard.title") }}</h2>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ $t("dashboard.subtitle") }}
        </span>
      </div>
      <div class="col-xl-8 ">
        <UsStatsConfiguration></UsStatsConfiguration>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <TrendingSearches :filter="this.filter"></TrendingSearches>
      </div>
      <div class="col-xl-6">
        <TotalQueriesWOResults :filter="this.filter"></TotalQueriesWOResults>
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import TrendingSearches from "@/view/content/widgets/analytics/TrendingSearches.vue";
import { mapGetters } from "vuex";
import TotalQueriesWOResults from "../content/widgets/analytics/TotalQueriesWOResults";
import UsStatsConfiguration from "@/view/content/widgets/USStatsConfiguration.vue";

export default {
  name: "dashboard",
  components: {
    UsStatsConfiguration,
    TotalQueriesWOResults,
    TrendingSearches
  },
  data() {
    return {
      filter: "search"
    };
  },
  computed: {
    project() {
      return this.currentProject.token;
    },
    changeProject() {
      return this.currentProject.token;
    },
    onDateRangeChanged() {
      return this.dateRange;
    },
    ...mapGetters(["currentProject", "dateRange"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
