<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        {{ localizedButtonTitle }}</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">{{ $t("fixit.dialog_model.title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="fixit.term"
                  required
                  :label="$t('fixit.dialog_model.fields.search_term.label')"
                  :disabled="disabled"
                  :hint="$t('fixit.dialog_model.fields.search_term.hint')"
                  ref="f_term"
                  :rules="[
                    v =>
                      !!v ||
                      this.$t('fixit.dialog_model.fields.search_term.rule')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="fixit.catalog_term"
                  required
                  :label="$t('fixit.dialog_model.fields.catalog_term.label')"
                  ref="f_catalog_term"
                  :rules="[
                    v =>
                      !!v ||
                      this.$t('fixit.dialog_model.fields.catalog_term.rule')
                  ]"
                ></v-text-field>
                <p v-if="isUrl(fixit.catalog_term)">
                  {{ $t("fixit.dialog_model.fields.catalog_term.url.text1") }}
                  <strong class="text-danger">{{ fixit.term }}</strong>
                  <strong>
                    {{
                      $t("fixit.dialog_model.fields.catalog_term.url.text2")
                    }}</strong
                  >
                  {{ $t("fixit.dialog_model.fields.catalog_term.url.text3") }}
                  <strong class="text-success">{{ fixit.catalog_term }}</strong>
                  {{ $t("fixit.dialog_model.fields.catalog_term.url.text4") }}
                </p>
                <p v-else>
                  {{
                    $t("fixit.dialog_model.fields.catalog_term.replace.text1")
                  }}
                  <strong class="text-danger">{{ fixit.term }}</strong>
                  <strong>
                    {{
                      $t("fixit.dialog_model.fields.catalog_term.replace.text2")
                    }}</strong
                  >
                  {{
                    $t("fixit.dialog_model.fields.catalog_term.replace.text3")
                  }}
                  <strong class="text-success">{{ fixit.catalog_term }}</strong
                  >.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="" text @click="showDialog = false">{{
            $t("common.close")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click.prevent="save"
            >{{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  DELETE_FIXIT,
  POST_FIXIT,
  PULL_FIXIT_LIST,
  PUT_FIXIT,
  CLOSE_DIALOG,
  REFRESH
} from "@/core/services/store/fixit.module";
import Swal from "sweetalert2";

export default {
  props: {
    button_title: {
      type: String,
      default: ""
    },
    term: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    }
  },
  data: () => ({
    valid: true,
    dialog: false
  }),
  methods: {
    isUrl: function(s) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(s);
    },
    refresh: function() {
      this.$store.dispatch(REFRESH);
      if (this.term !== "") {
        this.fixit.term = this.term;
      }
    },
    deleteItem() {
      this.$store.dispatch(DELETE_FIXIT, this.fixit.id).then(() => {
        this.showDialog = false;
        this.$store.dispatch(PULL_FIXIT_LIST);
        this.$store.dispatch(REFRESH);
        Swal.fire("Fixit deleted!", "", "success");
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.fixit.id > 0) {
          this.$store
            .dispatch(PUT_FIXIT, {
              id: this.fixit.id,
              data: {
                catalog_term: this.$refs.f_catalog_term.value,
                term: this.$refs.f_term.value
              }
            })
            .then(() => {
              this.showDialog = false;
              this.$store.dispatch(PULL_FIXIT_LIST);
              this.$store.dispatch(REFRESH);
              Swal.fire("Fixit updated!", "", "success");
              if (this.callback) {
                this.callback();
              }
            });
        } else {
          this.$store
            .dispatch(POST_FIXIT, {
              catalog_term: this.$refs.f_catalog_term.value,
              term: this.$refs.f_term.value
            })
            .then(() => {
              this.showDialog = false;
              this.$store.dispatch(PULL_FIXIT_LIST);
              Swal.fire("Fixit added!", "", "success");
              if (this.callback) {
                this.callback();
              }
            });
        }
      }
    },
    close() {
      this.$store.dispatch(CLOSE_DIALOG);
    }
  },
  validations: {
    form: {
      name: { required },
      type_id: { required }
    }
  },
  computed: {
    localizedButtonTitle() {
      return (
        this.button_title || this.$t("fixit.dialog_model.action.new_record")
      );
    },
    showDialog: {
      get: function() {
        return this.dialog || this.show_dialog;
      },
      set: function(value) {
        this.dialog = value;
        this.$store.dispatch(CLOSE_DIALOG);
      }
    },
    ...mapState({
      errors: state => state.fixit.errors,
      message: state => state.fixit.message,
      hasMessage: state => state.fixit.hasMessage,
      fixit: state => state.fixit.fixit,
      state: state => state.fixit.state,
      show_dialog: state => state.fixit.showDialog
    })
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
