var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"btn btn-primary font-weight-bolder",attrs:{"href":"#"},on:{"click":_vm.refresh}},on),[_vm._v(" "+_vm._s(_vm.localizedButtonTitle))])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("fixit.dialog_model.title")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"f_term",attrs:{"required":"","label":_vm.$t('fixit.dialog_model.fields.search_term.label'),"disabled":_vm.disabled,"hint":_vm.$t('fixit.dialog_model.fields.search_term.hint'),"rules":[
                  function (v) { return !!v ||
                    this$1.$t('fixit.dialog_model.fields.search_term.rule'); }
                ]},model:{value:(_vm.fixit.term),callback:function ($$v) {_vm.$set(_vm.fixit, "term", $$v)},expression:"fixit.term"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"f_catalog_term",attrs:{"required":"","label":_vm.$t('fixit.dialog_model.fields.catalog_term.label'),"rules":[
                  function (v) { return !!v ||
                    this$1.$t('fixit.dialog_model.fields.catalog_term.rule'); }
                ]},model:{value:(_vm.fixit.catalog_term),callback:function ($$v) {_vm.$set(_vm.fixit, "catalog_term", $$v)},expression:"fixit.catalog_term"}}),(_vm.isUrl(_vm.fixit.catalog_term))?_c('p',[_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.url.text1"))+" "),_c('strong',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fixit.term))]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.url.text2")))]),_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.url.text3"))+" "),_c('strong',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.fixit.catalog_term))]),_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.url.text4"))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.replace.text1"))+" "),_c('strong',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fixit.term))]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.replace.text2")))]),_vm._v(" "+_vm._s(_vm.$t("fixit.dialog_model.fields.catalog_term.replace.text3"))+" "),_c('strong',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.fixit.catalog_term))]),_vm._v(". ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(_vm._s(_vm.$t("common.close")))]),_c('v-spacer'),_c('v-btn',{ref:"submit",attrs:{"color":"primary dark large"},on:{"click":function($event){$event.preventDefault();return _vm.save($event)}}},[_vm._v(_vm._s(_vm.$t("common.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }